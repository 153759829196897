import React, { Component } from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ri-dashboard-line"></i>

                <span className="ml-1">{this.props.t("Dashboard")}</span>
              </Link>
            </li>

           
            {/* <li>
              <Link to="/group">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Groups")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/user">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Users")}</span>
              </Link>
            </li>

            <li>
              <Link to="/agents">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Agents")}</span>
              </Link>
            </li>

            <li>
              <Link to="/vendor">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Vendors")}</span>
              </Link>
            </li>

            <li>
              <Link to="/gifts">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Gifts")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/product-add">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Product")}</span>
              </Link>
            </li>

            <li>
              <Link to="/subscription-plan">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Subscription")}</span>
              </Link>
            </li>

            <li>
              <Link to="/subscription-history">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Subscription History")}</span>
              </Link>
            </li>

            <li>
              <Link to="/userlist">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("UserList")}</span>
              </Link>
            </li>

            <li>
              <Link to="/sellerlist">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("SellerList")}</span>
              </Link>
            </li>

            <li>
              <Link to="/service-category">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Service Category")}</span>
              </Link>
            </li>

            <li>
              <Link to="/subservice-category">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Sub Category")}</span>
              </Link>
            </li>

            <li>
              <Link to="/manageCoupon">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Coupon")}</span>
              </Link>
            </li>

            <li>
              <Link to="/orderHistory">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Order History ")}</span>
              </Link>
            </li>
            <li>
              <Link to="/servicebookinghistory">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Service Booking History ")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/monitize">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Loyalty")}</span>
              </Link>
            </li>
            <li>
              <Link to="/communicate">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Communicate")}</span>
              </Link>
            </li>
            <li>
              <Link to="/know-your-customer">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">
                  {this.props.t("Know Your Customer")}
                </span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="forum" className=" waves-effect">
                <i className="ri-calendar-2-line"></i>
                <span className="ml-1">{this.props.t("Forums")}</span>
              </Link>
            </li>
            <li>
              <Link to="subscrption" className=" waves-effect">
                <i className="ri-rss-fill"></i>
                <span className="ml-1">{this.props.t("Subscription")}</span>
              </Link>
            </li>

            <li>
              <Link to="directory" className=" waves-effect">
                <i className="ri-folder-user-line"></i>
                <span className="ml-1">{this.props.t("User Directory")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Withdraw")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="withdrawreq">
                    {this.props.t("Withdrawal Request")}
                  </Link>
                </li>
                <li>
                  <Link to="withdraw">
                    {this.props.t("Withdrawal History")}
                  </Link>
                </li>
              </ul>
              {/* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="withdraw">
                    {this.props.t("Withdrawal History")}
                  </Link>
                </li>
              </ul> */}
            </li>

            <li>
              <Link to="/help">
                <i className="ri-pencil-ruler-2-line"></i>
                <span className="ml-1">{this.props.t("Manage Help Content")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Product")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="product-add">
                    {this.props.t("Add & Manage Product")}
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* events */}
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Events")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/events">
                    {this.props.t("Add & manage events")}
                  </Link>
                </li>
                <li>
                  <Link to="/advertisement">
                    {this.props.t("Add & Manage Advertisement")}
                  </Link>
                </li>
              
              </ul>
            </li> */}
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-folder-shared-fill"></i>
                <span className="ml-1">{this.props.t("Panels")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="share-panel">{this.props.t("Share Panel")}</Link>
                </li>
                <li>
                  <Link to="request-panel">
                    {this.props.t("Request Panel")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-artboard-line"></i>
                <span className="ml-1">{this.props.t("Vision Board")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="timeline">
                    {this.props.t("Add & Manage Timeline")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-message-2-line"></i>
                <span className="ml-1">{this.props.t("Chat")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="appowner-chat">
                    {this.props.t("App Owner Chat")}
                  </Link>
                </li>
              </ul>
            </li>  */}
            {/* <li className="menu-title">{this.props.t('Pages')}</li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-share-line"></i>
                <span className="ml-1">{this.props.t("Multi Dropdown")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/#">{this.props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {this.props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/#">{this.props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{this.props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
            {/* <li className="menu-title">{this.props.t("OTT Menu")}</li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("User")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="User">
                <li>
                  <Link to="/users">
                    {this.props.t("Manage User")}
                  </Link>
                </li>
                <li>
                  <Link to="/user-subscription">
                    {this.props.t("User Subscriotion")}
                  </Link>
                </li>
                
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Types")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/types">{this.props.t("Add & Manage Types")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Category")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/jd3category">
                    {this.props.t("Add & Manage Category")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Genre")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/genre">{this.props.t("Add & Manage Genre")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Banner")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Banner">
                <li>
                  <Link to="/banner">
                    {this.props.t("Add & Manage Banner")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Subscription")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Subscription">
                <li>
                  <Link to="/subscription-plan">
                    {this.props.t("Manage Subscription")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Add Videos")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Videos">
                <li>
                  <Link to="/videos">
                    {this.props.t("Add & Manage Videos")}
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Series")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Series">
                <li>
                  <Link to="/series">
                    {this.props.t("Add & Manage Series")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Tv Shows")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Tv Shows">
                <li>
                  <Link to="/tv-shows">
                    {this.props.t("Add & Manage Tv Shows")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-mail-send-line"></i>
                <span className="ml-1">{this.props.t("Events")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="Events">
                <li>
                  <Link to="/training">
                    {this.props.t("Add & Manage Events")}
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
          {/* <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("JD3 Menu")}</li>
            
          </ul> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
