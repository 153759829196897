import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
} from "firebase/database";
import * as Store from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import UserMainModal from "./UserMainModal/UserMainModal";
import AddbalanceModal from "./UserMainModal/AddbalanceModal";
import DeductbalanceModal from "./UserMainModal/DeductbalanceModal";
var md5 = require("md5");
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "User", link: "#" },
        { title: "Add & Manage User", link: "#" },
      ],
      modaltype: false,
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      fname: "",
      lname: "",
      country: "",
      email: "",
      password: "",
      cpassword: "",
      uid: "",
      displayname: "",
      refresh: false,
      uploaded_images: [],
      cpassword: "",
      newpassword: "",
      balance: "",
      fcmtoken: "",
      imageurl: "",
      pass: "",
      upload: null,
      allTrans: [],
      uname: "",
      balModal: false,
      deductModal: false,
      userId: ''
    };
  }
  modalopen = (id) => {
    this.getAllTrans(id);
    this.setState({
      modaltype: true,
    });
  };

  balanceModal = (id) => {
    console.log('+++++++++++++++++++++')
    this.setState({
      userId: id,
      balModal: true,
    });
  };

  DeductbalanceModal = (id) => {
    console.log('-------')
    this.setState({
      userId: id,
      deductModal: true,
    });
  }

  CloseModal = () => {
    this.setState({
      balModal: false,
    });
  };

  CloseDeductModal = () => {
    this.setState({
      deductModal: false,
    });
  };

  CallBackView = () => {
    this.setState({
      modaltype: false,
      allTrans: [],
    });
  };

  getAllTrans = async (id) => {
    const dbRef = ref(db);
    get(child(dbRef, `/tranHistory/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        let transData = Object.values(snapshot.val());
        this.setState({
          allTrans: [...this.state.allTrans, transData],
        });
      }
    });
  };

  componentDidMount = async () => {
    this.fetch();
  };

  fetch = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `user`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
          this.setState({
            result: Object.values(snapshot.val()),
          });
          this.getFirebaseListener(Object.values(snapshot.val()));
          let data = [];
          let i = 1;
          let notdeleteduserarray = this.state.result.filter(
            (item) => !item.is_deleted
          );
          notdeleteduserarray.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.name,
              uname: element.userName,
              // pass: element.pass,
              balance: element.balance,
              img: <img src={element.image} width="50" height="50" />,
              history: (
                <>
                  <button
                    title="Transaction History"
                    className="btn btn-danger mr-2"
                    onClick={() => this.modalopen(element.id)}
                  >
                    {/* <i className="fa fa-trash" /> */}
                    <Label>Transaction History</Label>
                  </button>
                </>
              ),
              addBalance: (
                <>
                  <button
                    title="Add Balance"
                    className="btn btn-danger mr-2"
                    onClick={() => this.balanceModal(element.id)}
                  >
                    {/* <i className="fa fa-trash" /> */}
                    <Label>Add Balance</Label>
                  </button>

                  <button
                    title="Deducat Balance"
                    className="btn btn-primary"
                    onClick={() => this.DeductbalanceModal(element.id)}
                  >
                    {/* <i className="fa fa-trash" /> */}
                    <Label>Deduct Balance</Label>
                  </button>
                </>
              ),
              action: (
                <>
                  <button
                    title="Delete"
                    className="btn btn-danger mr-2"
                    onClick={() => {
                      this.delete(element, index);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </button>
                  <button
                    title="Edit"
                    className="btn btn-primary"
                    onClick={() => {
                      this.edit(element, index);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
          this.setState({
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    // this.setState({
    //   images: {},
    // });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  getFirebaseListener = async (oldVal) => {
    // console.log("/Chat/" + props.roomId + "/messages/");

    const dbRef = ref(db, "/user");

    const dbquery = query(dbRef);

    onChildAdded(dbquery, (snapshot) => {
      // console.log("snapshot.val()",snapshot.val());
      if (snapshot.exists()) {
        // console.log("fbData", snapshot.val());
        // console.log("oldVal", oldVal);
        let fbData = snapshot.val();
        let msgIndex = oldVal.findIndex((it) => it.id == fbData.id);
        if (msgIndex == -1) {
          this.setState({
            refresh: !this.state.refresh,
            result: [...this.state.result, fbData],
          });
          // console.log("resulttt",this.state.result);
        }
      } else {
        console.log("No data available");
      }
    });
  };

  delete = async (item, index) => {
    const postData = {
      id: item.id,
      name: item.name,
      displayName: item.displayName,
      pass: item.pass,
      balance: item.balance,
      image: item.image,
      fcmToken: item.fcmToken,
      type: "User",
      is_deleted: true,
    };
    const updates = {};
    // updates['/Chat/' + props.data+ '/members/' + id] = postData;
    // updates['/chatList/' + id + '/' + props.data] = postData;
    updates["/user/" + item.id] = postData;
    // console.log('/chatList/' + id + '/' + props.data);
    update(ref(db), updates);
    this.fetch();
    // const storage = Store.getStorage();

    // // Create a reference to the file to delete
    // const desertRef = Store.ref(storage, "GiftsImage/" + item.image);
    // // console.log("GiftsImage/" + this.state.image_name);

    // // Delete the file
    // Store.deleteObject(desertRef)
    //   .then(() => {
    //     console.log("File deleted successfully");
    //     this.fetch();
    //     // File deleted successfully
    //   })
    //   .catch((error) => {
    //     // Uh-oh, an error occurred!
    //   });
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,

      uploaded_images: item.image
        ? [
            {
              preview: item.image,
            },
          ]
        : [],
      balance: item.balance,
      displayname: item.displayName,
      fcmtoken: item.fcmToken,
      edit_item_id: item.id,
      imageurl: item.image,
      fname: item.name,
      pass: item.pass,
    });
  };
  submit = async () => {
    // console.log("object");
    if (this.state.fname == "" || this.state.uploaded_images.length == 0) {
      toast.warning("Please Fill all required fields!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // const dbRef = ref(db);

      // console.log("No data available");
      set(ref(db, "user/" + this.state.edit_item_id), {
        id: this.state.edit_item_id,
        name: this.state.fname,
        displayName: this.state.edit_item_id,
        pass: this.state.pass,
        balance: this.state.balance,
        image: this.state.imageurl,
        fcmToken: this.state.fcmtoken,
        type: "User",
      });
      this.fetch();

      // let data = {
      //   id: this.state.edit_item_id,
      //   name: this.state.fname,
      //   displayName: this.state.edit_item_id,
      //   pass: this.state.pass,
      //   balance: this.state.balance,
      //   image: this.state.imageurl,
      //   fcmToken: this.state.fcmtoken,
      //   type: "User",
      // };
      // console.log("data", data);
      toast.success("User Updated Successfully!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.setState({
        fname: "",
        lname: "",
        country: "",
        email: "",
        password: "",
        cpassword: "",
        uid: "",
        displayname: "",
        balance: "",
        uname: "",
        fcmtoken: "",
        imageurl: "",
        pass: "",
        edit_enable: false,
      });
    }
  };
  // passwordhandler=async(event)=>{
  //   const salt = await bcrypt.genSalt(16);
  //   const hashed = await bcrypt.hash(event.target.value, salt);
  //   console.log(hashed);
  // }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ country: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      // images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);

    const storage = Store.getStorage();
    // const storageRef = ref(storage, 'GiftsImage');
    const storageRef = Store.ref(storage, "UserImage/" + files[0].name);

    const uploadTask = Store.uploadBytesResumable(storageRef, files[0]);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("errror", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        Store.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          // progress = true;
          this.setState({
            imageurl: downloadURL,
            // upload: progress,
          });
        });
      }
    );
  };

  changepassword = async () => {
    if (this.state.cpassword != this.state.newpassword) {
      toast.warning("Password Not Matched!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return true;
    }
    const dbRef = ref(db);

    const postData = {
      id: this.state.edit_item_id,
      name: this.state.fname,
      displayName: this.state.edit_item_id,
      pass: md5(this.state.newpassword),
      balance: this.state.balance,
      image: this.state.imageurl,
      fcmToken: this.state.fcmtoken,
      type: "User",
    };
    console.log("postData", "/user/" + this.state.edit_item_id);
    const updates = {};
    updates["/user/" + this.state.edit_item_id] = postData;
    update(ref(db), updates);
    this.fetch();

    toast.success("Password Updated Successfully!", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    this.setState({
      email: "",
      password: "",
      fname: "",
      edit_enable: false,
    });
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Name",
          field: "uname",

          width: 270,
        },
        {
          label: "User Name",
          field: "name",

          width: 270,
        },
        // {
        //   label: "User Name",
        //   field: "displayName",

        //   width: 270,
        // },
        // {
        //   label: "Password",
        //   field: "pass",

        //   width: 270,
        // },
        {
          label: "Balance",
          field: "balance",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        {
          label: "Transaction History",
          field: "history",

          width: 100,
        },
        {
          label: "Add or Deduct Balance",
          field: "addBalance",

          width: 100,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  {this.state.edit_enable && (
                    <>
                      {" "}
                      <CardBody>
                        {this.state.alert ? (
                          <UncontrolledAlert
                            color={this.state.type}
                            className="alert-dismissible fade show"
                            role="alert"
                          >
                            {this.state.type == "warning" ? (
                              <i className="mdi mdi-alert-outline mr-2"></i>
                            ) : this.state.type == "success" ? (
                              <i className="mdi mdi-check-all mr-2"></i>
                            ) : this.state.type == "danger" ? (
                              <i className="mdi mdi-block-helper mr-2"></i>
                            ) : null}
                            {this.state.message}
                          </UncontrolledAlert>
                        ) : null}
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Name
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="text"
                              value={this.state.fname}
                              placeholder="EnterName"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ fname: val.target.value });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Image
                          </Label>
                          <Col md={10}>
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                this.handleAcceptedFiles(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {this.state.uploaded_images.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            // alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Button
                                            className="btn-icon"
                                            color="danger"
                                            style={{ marginRight: 25 }}
                                            onClick={(e) =>
                                              this.remove_image(f, i)
                                            }
                                          >
                                            {" "}
                                            <span className="btn-icon-label">
                                              <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                            </span>{" "}
                                            Remove
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-0">
                          <div className="button-items pt-4">
                            <Button
                              color="primary"
                              type="button"
                              className="waves-effect waves-light mr-1"
                              // disabled={this.state.upload}
                              onClick={() => {
                                this.submit();
                              }}
                            >
                              {this.state.edit_enable ? "Update" : "Submit"}{" "}
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                          </div>
                        </FormGroup>

                        <br></br>

                        <hr></hr>

                        <h3>Change Your Password</h3>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            New Password
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="password"
                              value={this.state.newpassword}
                              placeholder="Enter Password"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({
                                  newpassword: val.target.value,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Confirm Password
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="password"
                              value={this.state.cpassword}
                              placeholder="Enter Password"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({
                                  cpassword: val.target.value,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-0">
                          <div className="button-items pt-4">
                            <Button
                              color="primary"
                              type="button"
                              className="waves-effect waves-light mr-1"
                              onClick={() => {
                                this.changepassword();
                              }}
                            >
                              Change Password
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                          </div>
                        </FormGroup>
                      </CardBody>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={7}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className={
            this.state.modaltype ? "modal fade show modal_bg" : "modal fade"
          }
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: this.state.modaltype ? "block" : "none" }}
        >
          <UserMainModal
            close={this.CallBackView}
            data={this.state.allTrans}
            user={"male"}
          />
        </div>

        <div
          className={
            this.state.balModal ? "modal fade show modal_bg" : "modal fade"
          }
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: this.state.balModal ? "block" : "none" }}
        >
          <AddbalanceModal
            close={this.CloseModal}
            data={this.state.userId}
            user={"male"}
          />
        </div>

        <div
          className={
            this.state.deductModal ? "modal fade show modal_bg" : "modal fade"
          }
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: this.state.deductModal ? "block" : "none" }}
        >
          <DeductbalanceModal
            close={this.CloseDeductModal}
            data={this.state.userId}
            user={"male"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
