import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import * as Store from "firebase/storage";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
  push,
} from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
var md5 = require("md5");
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Gifts", link: "#" },
        { title: "Add & Manage Gifts", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      fname: "",
      lname: "",
      country: "",
      email: "",
      password: "",
      cpassword: "",
      uid: "",
      displayname: "",
      refresh: false,
      uploaded_images: [],
      giftname: "",
      price: "",
      url: "",
      images: "",
      image_name: "",
      upload: null,
    };
  }

  componentDidMount = async () => {
    this.fetch();
  };

  fetch = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `gifts`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
          this.setState({
            result: Object.values(snapshot.val()),
          });

          let data = [];
          let i = 1;
          let notdeleteduserarray = this.state.result.filter(
            (item) => !item.is_deleted
          );
          notdeleteduserarray.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.name,
              price: element.price,
              img: <img src={element.image} width="50" height="50" />,
              action: (
                <>
                  <button
                    title="Delete"
                    className="btn btn-danger mr-2"
                    onClick={() => {
                      this.delete(element, index);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </button>
                  <button
                    title="Edit"
                    className="btn btn-primary"
                    onClick={() => {
                      this.edit(element, index);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
          this.setState({
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    // this.setState({
    //   images: {},
    // });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  delete = async (item, index) => {
    const postData = {
      image: item.image,
      name: item.name,
      price: item.price,
      nodeId: item.nodeId,
      imagename: item.imagename,
      is_deleted: true,
    };
    const updates = {};
    // updates['/Chat/' + props.data+ '/members/' + id] = postData;
    // updates['/chatList/' + id + '/' + props.data] = postData;
    updates["/gifts/" + item.nodeId] = postData;
    // console.log('/chatList/' + id + '/' + props.data);
    update(ref(db), updates);
    const storage = Store.getStorage();

    // Create a reference to the file to delete
    const desertRef = Store.ref(storage, "GiftsImage/" + item.imagename);
    // console.log("GiftsImage/" + this.state.image_name);

    // Delete the file
    Store.deleteObject(desertRef)
      .then(() => {
        console.log("File deleted successfully");
        this.fetch();
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });

    // this.fetch();
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      giftname: item.name,
      price: item.price,
      edit_item_id: item.nodeId,
      uploaded_images: item.image
        ? [
            {
              preview: item.image,
            },
          ]
        : [],
      image_name: item.imagename,
    });
  };
  submit = async () => {
    if (this.state.edit_enable) {
      if (
        this.state.giftname == "" ||
        this.state.price == "" ||
        this.state.uploaded_images.length == 0
      ) {
        toast.warning("Please Fill all required fields!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const postData = {
          image: this.state.url,
          name: this.state.giftname,
          price: this.state.price,
          nodeId: this.state.edit_item_id,
          imagename: this.state.images,
        };
        const updates = {};
        // updates['/Chat/' + props.data+ '/members/' + id] = postData;
        // updates['/chatList/' + id + '/' + props.data] = postData;
        updates["/gifts/" + this.state.edit_item_id] = postData;
        // console.log('/chatList/' + id + '/' + props.data);
        update(ref(db), updates);

        toast.success("Gift Updated Successfully!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.fetch();

        this.setState({
          giftname: "",
          price: "",
          uploaded_images: [],
        });
      }
    } else {
      if (
        this.state.giftname == "" ||
        this.state.price == "" ||
        this.state.uploaded_images.length == 0
      ) {
        toast.warning("Please Fill all required fields!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const postListRef = ref(db, "gifts");
        const newPostRef = push(postListRef);
        set(newPostRef, {
          image: this.state.url,
          name: this.state.giftname,
          price: this.state.price,
          nodeId: newPostRef.key,
          imagename: this.state.images,
        });

        toast.success("Gift Added Successfully!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        this.fetch();

        this.setState({
          giftname: "",
          price: "",
          uploaded_images: [],
        });
      }
    }
  };
  // passwordhandler=async(event)=>{
  //   const salt = await bcrypt.genSalt(16);
  //   const hashed = await bcrypt.hash(event.target.value, salt);
  //   console.log(hashed);
  // }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ country: event.target.value });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleAcceptedFiles = (files) => {
    let progress = false;
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      images: files[0].name,
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
    const storage = Store.getStorage();
    // const storageRef = ref(storage, 'GiftsImage');
    const storageRef = Store.ref(storage, "GiftsImage/" + files[0].name);

    const uploadTask = Store.uploadBytesResumable(storageRef, files[0]);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("errror", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        Store.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          progress = true;
          this.setState({
            url: downloadURL,
            upload: progress,
          });
        });
      }
    );
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Price",
          field: "price",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.giftname}
                          placeholder="Enter Gift Name"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ giftname: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Price
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.price}
                          placeholder="Enter Price"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ price: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Image
                      </Label>
                      <Col md={10}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.uploaded_images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        // alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Button
                                        className="btn-icon"
                                        color="danger"
                                        style={{ marginRight: 25 }}
                                        onClick={(e) => this.remove_image(f, i)}
                                      >
                                        {" "}
                                        <span className="btn-icon-label">
                                          <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                        </span>{" "}
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </FormGroup>

                    {/* <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          value={this.state.password}
                          placeholder="Enter Password"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ password: val.target.value });
                            console.log(md5(this.state.password));
                          }}
                          // onchange={this.passwordhandler}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Confirm Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="Password"
                          value={this.state.cpassword}
                          placeholder="Enter Confirm Password"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ cpassword: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup> */}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                          disabled={
                            !this.state.upload && !this.state.edit_enable
                          }
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
