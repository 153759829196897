import React, { useState, useEffect } from "react";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
// import ReactJsAlert from "reactjs-alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import db from "../../Firebase/fbconfig";
import { getDatabase, ref, child, get,update } from "firebase/database";
function MemberDetails(props) {
  useEffect(() => {
    // console.log("objecttt", props.data);
    fetchmember(props.data);
  }, [props.data]);
  const [memberlist, setmemberlist] = useState([]);
  const [data, setdata] = useState([]);
  const onCloseMethod = async () => {
    props.onCloseCallBack(false);
  };


  // const delchatlist=async(id)=>{
  //   const postData = {};
  //   const updates = {};
  //   updates['/chatList/' + id + '/' + props.data] = postData;
  //   return update(ref(db), updates);
  // }
  const deletemember = async (id) => {
    const postData = null;
    const updates = {};
    updates['/Chat/' + props.data+ '/members/' + id] = postData;
    updates['/chatList/' + id + '/' + props.data] = postData;
    // console.log('/chatList/' + id + '/' + props.data);
    update(ref(db), updates)
    fetchmember(props.data);
    // const dbRef = ref(db);
    // get(child(dbRef, "Chat/" + props.data + "/members/" + id))
    //   .then((snapshot) => {
    //     // let arr = [];
    //     if (snapshot.exists()) {
    //       console.log(snapshot.val());
    //       if (snapshot.val().admin == true) {
    //         toast.error("Member Cannot be deleted!", {
    //           position: "bottom-center",
    //           autoClose: 1000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //       } else {
    //         // console.log("false admin");
    //         const postData = {};
    //         const updates = {};
    //         updates['/Chat/' + props.data+ '/members/' + id] = postData;
    //         return update(ref(db), updates), fetchmember(props.data);
    //       }
    //     } else {
    //       console.log("No data available");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const fetchmember = async (id) => {
    //  console.log(id);
    const dbRef = ref(db);
    get(child(dbRef, "Chat/" + id + "/members"))
      .then((snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          console.log(snapshot.val());
          snapshot.forEach((element) => {
            let dom = {
              id: element.val().mem_Id,
              name: element.val().mem_name,
            };
            console.log("dom", dom);
            arr.push(dom);
          });
          console.log("arr", arr);
          setmemberlist(arr);
          let data = [];
          let i = 1;
          arr.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.name,
              memberid: element.id,
              action: (
                <>
                  <button
                    title="Delete"
                    className="btn btn-danger mr-2"
                    // onClick={() => {
                    //   this.setState({
                    //     activeid: element.id,
                    //   });
                    //   setTimeout(() => {
                    //     this.setState({
                    //       ismodalopen: true,
                    //     });
                    //   }, 200);
                    // }}
                    onClick={() => deletemember(element.id)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });

          setdata(data);
        } else {
          setdata([])
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dataa = {
    columns: [
      {
        label: "Sl.",
        field: "sl",

        width: 150,
      },
      {
        label: "Member Name",
        field: "name",

        width: 270,
      },
      {
        label: "Member Id",
        field: "memberid",

        width: 270,
      },
      // {
      //   label: "Image",
      //   field: "img",
      //
      //   width: 270,
      // },
      {
        label: "Action",
        field: "action",

        width: 100,
      },
    ],
    rows: data,
  };

  // const { selectedOption } = this.state;
  return (
    <div
      className={props.status ? "modal show fade" : "modal fade"}
      id="add-new-task-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="add-new-task-modalLabel"
      aria-hidden="true"
      style={{ display: props.status ? "block" : "none" }}
    >
      <ToastContainer />
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="add-new-task-modalLabel">
              Member Details
            </h4>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                onCloseMethod();
              }}
            >
              ×
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "400px", overflowY: "auto" }}
          >
            <div className="px-2"></div>
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={dataa}
            />
            <div className="text-right">
              <button
                type="button"
                className="btn btn-warning mr-3"
                data-dismiss="modal"
                onClick={() => {
                  onCloseMethod();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}
export default MemberDetails;
