import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
  push,
} from "firebase/database";
import * as Store from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
var md5 = require("md5");
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Withdrawal History", link: "#" },
        { title: "View Withdrawal History", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      fname: "",
      lname: "",
      country: "",
      email: "",
      password: "",
      cpassword: "",
      uid: "",
      displayname: "",
      refresh: false,
      uploaded_images: [],
      cpassword: "",
      newpassword: "",
      balance: "",
      fcmtoken: "",
      imageurl: "",
      pass: "",
      upload: null,
    };
  }

  componentDidMount = async () => {
    this.fetch();
  };

  fetch = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `Withdrawn`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
          this.setState({
            result: Object.values(snapshot.val()),
          });

          let data = [];
          let i = 1;

          this.state.result.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.username,
              type: element.type,
              money: element.money,
              img: <img src={element.image} width="50" height="50" />,
              //   action: (
              //     <>
              //       <Button
              //         onClick={() => {
              //           this.withdraw(element, index);
              //         }}
              //       >
              //         Withdraw
              //       </Button>
              //     </>
              //   ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
          this.setState({
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "User Name",
          field: "name",

          width: 270,
        },
        {
          label: "User Type",
          field: "type",

          width: 270,
        },
        {
          label: "Requested Withdrawal Money",
          field: "money",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        // {
        //   label: "Action",
        //   field: "action",

        //   width: 100,
        // },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
