import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
  push,
} from "firebase/database";
import * as Store from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
var md5 = require("md5");
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Withdraw Request", link: "#" },
        { title: "Manage Withdraw Request", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      fname: "",
      lname: "",
      country: "",
      email: "",
      password: "",
      cpassword: "",
      uid: "",
      displayname: "",
      refresh: false,
      uploaded_images: [],
      cpassword: "",
      newpassword: "",
      balance: "",
      fcmtoken: "",
      imageurl: "",
      pass: "",
      upload: null,
    };
  }

  componentDidMount = async () => {
    this.fetch();
  };

  fetch = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `withdrawlReq`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
          this.setState({
            result: Object.values(snapshot.val()),
          });

          let data = [];
          let i = 1;

          this.state.result.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.username,
              type: element.type,
              money: element.money,
              img: <img src={element.image} width="50" height="50" />,
              action: (
                <>
                  <Button
                    onClick={() => {
                      this.withdraw(element, index);
                    }}
                  >
                    Withdraw
                  </Button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
          this.setState({
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  withdraw = async (item) => {
    if (item.type == "Female") {
      let userData = {};
      const dbRef = ref(db);
      get(child(dbRef, `femaleUser/${item.username}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
            userData = {
              balance: snapshot.val().balance - item.money,
              displayName: snapshot.val().displayName,
              email: snapshot.val().email,
              fcmToken: snapshot.val().fcmToken,
              id: snapshot.val().id,
              image: snapshot.val().image,
              moboNo: snapshot.val().moboNo,
              name: snapshot.val().name,
              pass: snapshot.val().pass,
              type: snapshot.val().type,
              userName: snapshot.val().userName,
              vendorId: snapshot.val().vendorId,
            };
            const emptyData = {};

            const postData = {
              account_name: item.account_name,
              account_number: item.account_number,
              bank_name: item.bank_name,
              ifsc: item.ifsc,
              image: item.image,
              money: item.money,
              nodeId: item.nodeId,
              type: item.type,
              username: item.username,
            };

            console.log(postData);

            const updates = {};

            updates["/femaleUser/" + item.username] = userData;

            updates["/withdrawlReq/" + item.nodeId] = emptyData;

            update(ref(db), updates);

            const postListRef = ref(db, "/Withdrawn");
            const newPostRef = push(postListRef);
            set(newPostRef, {
              account_name: item.account_name,
              account_number: item.account_number,
              bank_name: item.bank_name,
              ifsc: item.ifsc,
              image: item.image,
              money: item.money,
              nodeId: item.nodeId,
              type: item.type,
              username: item.username,
            });

            this.fetch();
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
      return true;
    }

    let userData = {};
    const dbRef = ref(db);
    get(child(dbRef, `vendor/${item.username}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          userData = {
            balance: snapshot.val().balance - item.money,
            displayName: snapshot.val().displayName,

            id: snapshot.val().id,
            image: snapshot.val().image,

            name: snapshot.val().name,
            pass: snapshot.val().pass,
            type: snapshot.val().type,
            username: snapshot.val().username,
          };
          const emptyData = {};

          const postData = {
            account_name: item.account_name,
            account_number: item.account_number,
            bank_name: item.bank_name,
            ifsc: item.ifsc,
            image: item.image,
            money: item.money,
            nodeId: item.nodeId,
            type: item.type,
            username: item.username,
          };

          console.log(userData);

          const updates = {};

        //   console.log("/withdrawlReq/" + item.nodeId);

          updates["/vendor/" + item.username] = userData;

          updates["/withdrawlReq/" + item.nodeId] = emptyData;

          update(ref(db), updates);

          const postListRef = ref(db, "/Withdrawn");
          const newPostRef = push(postListRef);
          set(newPostRef, {
            account_name: item.account_name,
            account_number: item.account_number,
            bank_name: item.bank_name,
            ifsc: item.ifsc,
            image: item.image,
            money: item.money,
            nodeId: item.nodeId,
            type: item.type,
            username: item.username,
          });

          this.fetch();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,

      uploaded_images: item.image
        ? [
            {
              preview: item.image,
            },
          ]
        : [],
      balance: item.balance,
      displayname: item.displayName,
      fcmtoken: item.fcmToken,
      edit_item_id: item.id,
      imageurl: item.image,
      fname: item.name,
      pass: item.pass,
    });
  };
  submit = async () => {
    // console.log("object");
    if (this.state.fname == "" || this.state.uploaded_images.length == 0) {
      toast.warning("Please Fill all required fields!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // const dbRef = ref(db);

      // console.log("No data available");
      set(ref(db, "user/" + this.state.edit_item_id), {
        id: this.state.edit_item_id,
        name: this.state.fname,
        displayName: this.state.edit_item_id,
        pass: this.state.pass,
        balance: this.state.balance,
        image: this.state.imageurl,
        fcmToken: this.state.fcmtoken,
        type: "User",
      });
      this.fetch();

      // let data = {
      //   id: this.state.edit_item_id,
      //   name: this.state.fname,
      //   displayName: this.state.edit_item_id,
      //   pass: this.state.pass,
      //   balance: this.state.balance,
      //   image: this.state.imageurl,
      //   fcmToken: this.state.fcmtoken,
      //   type: "User",
      // };
      // console.log("data", data);
      toast.success("User Updated Successfully!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.setState({
        fname: "",
        lname: "",
        country: "",
        email: "",
        password: "",
        cpassword: "",
        uid: "",
        displayname: "",
        balance: "",
        fcmtoken: "",
        imageurl: "",
        pass: "",
        edit_enable: false,
      });
    }
  };
  // passwordhandler=async(event)=>{
  //   const salt = await bcrypt.genSalt(16);
  //   const hashed = await bcrypt.hash(event.target.value, salt);
  //   console.log(hashed);
  // }
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ country: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      // images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);

    const storage = Store.getStorage();
    // const storageRef = ref(storage, 'GiftsImage');
    const storageRef = Store.ref(storage, "UserImage/" + files[0].name);

    const uploadTask = Store.uploadBytesResumable(storageRef, files[0]);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("errror", error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        Store.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          // progress = true;
          this.setState({
            imageurl: downloadURL,
            // upload: progress,
          });
        });
      }
    );
  };

  changepassword = async () => {
    if (this.state.cpassword != this.state.newpassword) {
      toast.warning("Password Not Matched!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return true;
    }
    const dbRef = ref(db);

    const postData = {
      id: this.state.edit_item_id,
      name: this.state.fname,
      displayName: this.state.edit_item_id,
      pass: md5(this.state.newpassword),
      balance: this.state.balance,
      image: this.state.imageurl,
      fcmToken: this.state.fcmtoken,
      type: "User",
    };
    console.log("postData", "/user/" + this.state.edit_item_id);
    const updates = {};
    updates["/user/" + this.state.edit_item_id] = postData;
    update(ref(db), updates);
    this.fetch();

    toast.success("Password Updated Successfully!", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    this.setState({
      email: "",
      password: "",
      fname: "",
      edit_enable: false,
    });
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "User Name",
          field: "name",

          width: 270,
        },
        {
          label: "User Type",
          field: "type",

          width: 270,
        },
        {
          label: "Requested Withdrawal Money",
          field: "money",

          width: 270,
        },
        {
          label: "Image",
          field: "img",

          width: 270,
        },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
