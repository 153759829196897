// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBSc3QYk8b-y3jILk2iyVR1YQPYeDjV-NQ",
//   authDomain: "hellohii-e8429.firebaseapp.com",
//   databaseURL: "https://hellohii-e8429-default-rtdb.firebaseio.com",
//   projectId: "hellohii-e8429",
//   storageBucket: "hellohii-e8429.appspot.com",
//   messagingSenderId: "1081869041388",
//   appId: "1:1081869041388:web:159958ea1a7d04e995afc9",
//   measurementId: "G-39MSWYTV21"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCris1msK9IvmY2qWRY2udo5VRTmlYu1B0",
  authDomain: "hellohii-633b1.firebaseapp.com",
  databaseURL: "https://hellohii-633b1-default-rtdb.firebaseio.com",
  projectId: "hellohii-633b1",
  storageBucket: "hellohii-633b1.appspot.com",
  messagingSenderId: "1077683581749",
  appId: "1:1077683581749:web:cba2bcf8a03aff2ce9127c",
  measurementId: "G-EJPJ67R6QQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getDatabase();

export default db;
