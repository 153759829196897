import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Button,
    UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import * as Store from "firebase/storage";
import {
    getDatabase,
    ref,
    child,
    get,
    set,
    update,
    onChildAdded,
    query,
    push,
} from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
var md5 = require("md5");
class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Gifts", link: "#" },
                { title: "Add & Manage Gifts", link: "#" },
            ],
            customchk: true,
            toggleSwitch: true,
            // page states
            name: "",
            desc: "",
            duration: "",
            price: "",
            data: [],
            alert: false,
            message: "",
            type: "",
            result: [],
            edit_enable: false,
            edit_item_id: "",
            image_select: false,
            img_url: "",
            category_type: "",
            size: [{ name: "" }],
            fname: "",
            lname: "",
            country: "",
            email: "",
            password: "",
            cpassword: "",
            uid: "",
            displayname: "",
            refresh: false,
            uploaded_images: [],
            giftname: "",
            price: "",
            url: "",
            images: "",
            image_name: "",
            upload: null,
            helpItems: [
                {
                    title: '',
                    video: ''
                }
            ]
        };
    }

    componentDidMount = async () => {
        this.fetch();
    };

    fetch = () => {
        get(ref(db, 'help')).then(snapshot => {
            console.log("snapshot", snapshot.val())
            if(snapshot.exists()){
                this.setState({
                    helpItems: snapshot.val().help
                })
            }
        })
    }

    async remove_image(item, index) {
        this.state.uploaded_images.pop(index);
        this.setState({});
    }

    delete = async (item, index) => {
        const postData = {
            image: item.image,
            name: item.name,
            price: item.price,
            nodeId: item.nodeId,
            imagename: item.imagename,
            is_deleted: true,
        };
        const updates = {};
        // updates['/Chat/' + props.data+ '/members/' + id] = postData;
        // updates['/chatList/' + id + '/' + props.data] = postData;
        updates["/gifts/" + item.nodeId] = postData;
        // console.log('/chatList/' + id + '/' + props.data);
        update(ref(db), updates);
        const storage = Store.getStorage();

        // Create a reference to the file to delete
        const desertRef = Store.ref(storage, "GiftsImage/" + item.imagename);
        // console.log("GiftsImage/" + this.state.image_name);

        // Delete the file
        Store.deleteObject(desertRef)
            .then(() => {
                console.log("File deleted successfully");
                // this.fetch();
                // File deleted successfully
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
            });

        // this.fetch();
    };
    edit = async (item, index) => {
        this.setState({
            edit_enable: true,
            giftname: item.name,
            price: item.price,
            edit_item_id: item.nodeId,
            uploaded_images: item.image
                ? [
                    {
                        preview: item.image,
                    },
                ]
                : [],
            image_name: item.imagename,
        });
    };
    submit = async () => {
        const postListRef = ref(db, "help");
        // const newPostRef = push(postListRef);
        set(postListRef, {
            help: this.state.helpItems
        });

        toast.success("Update Successfully!", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        // this.fetch();

        this.setState({
            giftname: "",
            price: "",
            uploaded_images: [],
        });
    };

    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ country: event.target.value });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    handleAcceptedFiles = (files, index) => {
        let progress = false;
        // console.log("object", event.target.files[0]);
        // setprofile(event.target.files[0]);
        let file = {
            preview: URL.createObjectURL(files[0]),
            formattedSize: this.formatBytes(files[0].size),
        };
        console.log("image", files[0]);
        let arr = [];
        arr.push(file);
        this.state.helpItems[index].video = files[0].path
        this.setState({
            images: files[0].name,
            uploaded_images: arr,
        });
        console.log("uploaded_images", this.state.uploaded_images);
        const storage = Store.getStorage();
        // const storageRef = ref(storage, '');
        const storageRef = Store.ref(storage, "GiftsImage/" + files[0].name);

        const uploadTask = Store.uploadBytesResumable(storageRef, files[0]);

        // // Register three observers:
        // // 1. 'state_changed' observer, called any time the state changes
        // // 2. Error observer, called on failure
        // // 3. Completion observer, called on successful completion
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.log("errror", error);
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                Store.getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);
                    progress = true;
                    this.state.helpItems[index].video = downloadURL
                    this.setState({
                        url: downloadURL,
                        upload: progress,
                    });
                });
            }
        );
    };
    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={this.state.breadcrumbItems[0].title}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.alert ? (
                                            <UncontrolledAlert
                                                color={this.state.type}
                                                className="alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.type == "warning" ? (
                                                    <i className="mdi mdi-alert-outline mr-2"></i>
                                                ) : this.state.type == "success" ? (
                                                    <i className="mdi mdi-check-all mr-2"></i>
                                                ) : this.state.type == "danger" ? (
                                                    <i className="mdi mdi-block-helper mr-2"></i>
                                                ) : null}
                                                {this.state.message}
                                            </UncontrolledAlert>
                                        ) : null}

                                        {
                                            this.state.helpItems.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <FormGroup row>
                                                            <Label
                                                                htmlFor="example-text-input"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Title
                                                            </Label>
                                                            <Col md={10}>
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={item.title}
                                                                    placeholder="Enter Title"
                                                                    id="example-text-input"
                                                                    onChange={(event) => {
                                                                        this.state.helpItems[index].title = event.target.value;
                                                                        this.setState({})
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup row>
                                                            <Label
                                                                htmlFor="example-text-input"
                                                                className="col-md-2 col-form-label"
                                                            >
                                                                Video
                                                            </Label>
                                                            <Col md={10}>
                                                                <Dropzone
                                                                    accept="video/*"
                                                                    onDrop={(acceptedFiles) =>
                                                                        this.handleAcceptedFiles(acceptedFiles, index)
                                                                    }
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="dropzone">
                                                                            <div
                                                                                className="dz-message needsclick mt-2"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                                </div>
                                                                                <h4>Drop files here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {
                                                                        item.video != '' ?
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={"0-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">
                                                                                            <video
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                // alt={f.name}
                                                                                                src={item.video}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Button
                                                                                                className="btn-icon"
                                                                                                color="danger"
                                                                                                style={{ marginRight: 25 }}
                                                                                                onClick={(e) => {
                                                                                                    this.state.helpItems[index].video = '';
                                                                                                    this.setState({})
                                                                                                }}
                                                                                            >
                                                                                                {" "}
                                                                                                <span className="btn-icon-label">
                                                                                                    <i className="mdi mdi-alpha-x-box-outline mr-2"></i>
                                                                                                </span>{" "}
                                                                                                Remove
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                        {
                                                            (this.state.helpItems.length - 1) == index ?
                                                                <FormGroup className="mb-0">
                                                                    <div className="button-items pt-4">
                                                                        <Button
                                                                            color="primary"
                                                                            type="button"
                                                                            className="waves-effect waves-light mr-1"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    helpItems: [...this.state.helpItems, { title: '', video: '' }]
                                                                                })
                                                                            }}
                                                                        >
                                                                            Add More
                                                                            {/* <i className="ri-arrow-right-line align-middle ml-1"></i> */}
                                                                        </Button>
                                                                    </div>
                                                                </FormGroup>
                                                                :
                                                                <FormGroup className="mb-0">
                                                                    <div className="button-items pt-4">
                                                                        <Button
                                                                            color="danger"
                                                                            type="button"
                                                                            className="waves-effect waves-light mr-1"
                                                                            onClick={() => {
                                                                                this.state.helpItems.splice(index, 1)
                                                                                this.setState({})
                                                                            }}
                                                                        >
                                                                            Remove
                                                                            {/* <i className="ri-arrow-right-line align-middle ml-1"></i> */}
                                                                        </Button>
                                                                    </div>
                                                                </FormGroup>
                                                        }

                                                    </div>
                                                )
                                            })
                                        }

                                        <FormGroup className="mb-0">
                                            <div className="button-items pt-4">
                                                <Button
                                                    color="primary"
                                                    type="button"
                                                    className="waves-effect waves-light mr-1"
                                                    onClick={() => {
                                                        this.submit();
                                                    }}
                                                >
                                                    Submit
                                                    <i className="ri-arrow-right-line align-middle ml-1"></i>
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Help;
