import React from "react";
import moment from "moment";

const UserMainModal = (props) => {
  return (
    <div>
      <div className="modal-dialog ">
        <div
          className="modal-content modal_body"
          style={{ height: "450px", overflowY: "auto" }}
        >
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.close()}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body ">
            <h4 className="text-center">Transaction History</h4>
            {props.data[0]
              ? props.data[0].reverse().map((item) => (
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex ">
                        <i className="fas fa-phone-square-alt fa-3x text-danger"></i>
                        <div className="mx-4">
                          <h6>{item.msg}</h6>
                          <p>{item.subMsg}</p>
                        </div>
                        <div className="ml-auto text-right">
                        {props.user == 'male' ?
                          <h6>
                            {item.type == "ADD" ? "+" : "-"} ₹
                            {Number(item.amount).toFixed(2)}
                          </h6>
                          :
                          <h6>
                            + ₹
                            {Number(item.amount).toFixed(2)}
                          </h6>
                          }
                          <p>{moment(item.time).format("Do MMM YY hh:mm A")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMainModal;
