import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import Dropzone from "react-dropzone";

import db from "../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
} from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import * as Store from "firebase/storage";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
var md5 = require("md5");
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Vendor", link: "#" },
        { title: "Add & Manage Vendor", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      fname: "",
      lname: "",
      country: "",
      email: "",
      password: "",
      cpassword: "",
      uid: "",
      displayname: "",
      refresh: false,
      uploaded_images: [],
      newpassword: "",
      cpassword: "",
      fetchpass: "",
      recieved_url: "",
      balance: "",
    };
  }

  componentDidMount = async () => {
    this.fetch();
    this.geturl();
  };
  geturl = async () => {
    const storage = Store.getStorage();
    const storageRef = Store.ref(storage, "/UserImage/User-Profile-PNG.png");
    let url = await Store.getDownloadURL(storageRef);
    console.log("url", url);
    this.setState({
      recieved_url: url,
    });
    // return url;
  };
  fetch = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `vendor`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
          this.setState({
            result: Object.values(snapshot.val()),
          });

          let data = [];
          let i = 1;
          let notdeleteduserarray = this.state.result.filter(
            (item) => !item.is_deleted
          );
          notdeleteduserarray.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.name,
              email: element.username,

              action: (
                <>
                  <button
                    title="Delete"
                    className="btn btn-danger mr-2"
                    onClick={() => {
                      this.delete(element, index);
                    }}
                  >
                    <i className="fa fa-trash" />
                  </button>
                  <button
                    title="Edit"
                    className="btn btn-primary"
                    onClick={() => {
                      this.edit(element, index);
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
          this.setState({
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async remove_image(item, index) {
    // this.state.selectedFiles.pop(index);
    // this.state.images.pop(index);
    // this.setState({
    //   images: {},
    // });
    this.state.uploaded_images.pop(index);
    this.setState({});
  }

  delete = async (item, index) => {
    const postData = {
      id: item.id,
      username: item.username,
      pass: item.pass,
      name: item.name,
      balance: item.balance,
      type: "Vendor",
      displayName: item.displayName,
      image: item.image,
      is_deleted: true,
    };
    const updates = {};
    // updates['/Chat/' + props.data+ '/members/' + id] = postData;
    // updates['/chatList/' + id + '/' + props.data] = postData;
    updates["/vendor/" + item.id] = postData;
    // console.log('/chatList/' + id + '/' + props.data);
    update(ref(db), updates);
    this.fetch();
  };
  edit = async (item, index) => {
    this.setState({
      edit_enable: true,
      fname: item.name,
      email: item.username,
      edit_item_id: item.id,
      password: item.password,
      balance: item.balance,
      // image:item.image
    });
  };
  submit = async () => {
    // console.log("object");
    if (this.state.edit_enable) {
      const dbRef = ref(db);

      const postData = {
        id: this.state.edit_item_id,
        username: this.state.edit_item_id,
        pass: this.state.password,
        name: this.state.fname,
        balance: this.state.balance,
        type: "Vendor",
        displayName: this.state.edit_item_id,
        image: this.state.recieved_url,
      };
      const updates = {};
      updates["/vendor/" + this.state.edit_item_id] = postData;
      update(ref(db), updates);
      this.fetch();

      toast.success("Vendor Updated Successfully!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.setState({
        email: "",
        password: "",
        fname: "",
        edit_enable: false,
      });
    } else {
      if (
        this.state.email == "" ||
        this.state.password == "" ||
        this.state.fname == ""
      ) {
        toast.warning("Please Fill all required fields!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const dbRef = ref(db);
        get(child(dbRef, `vendor/${this.state.email}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              // console.log(snapshot.val());
              toast.error("Vendor already Exists!", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              
              // console.log("No data available");
              set(ref(db, "vendor/" + this.state.email), {
                id: this.state.email,
                username: this.state.email,
                pass: md5(this.state.password),
                name: this.state.fname,
                balance: 0,
                type: "Vendor",
                displayName: this.state.email,
                image: this.state.recieved_url,
              });
              toast.success("Vendor Added Successfully!", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              this.setState({
                email: "",
                password: "",
                fname: "",
              });
              this.fetch();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  changepassword = async () => {
    if (this.state.cpassword != this.state.newpassword) {
      toast.warning("Password Not Matched!", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return true;
    }
    const dbRef = ref(db);

    const postData = {
      id: this.state.edit_item_id,
      username: this.state.edit_item_id,
      pass: md5(this.state.newpassword),
      name: this.state.fname,
      balance: this.state.balance,
      type: "Vendor",
      displayName: this.state.edit_item_id,
      image: this.state.recieved_url,
    };
    console.log(postData);
    const updates = {};
    updates["/vendor/" + this.state.edit_item_id] = postData;
    update(ref(db), updates);
    this.fetch();

    toast.success("Password Updated Successfully!", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    this.setState({
      email: "",
      password: "",
      fname: "",
      edit_enable: false,
    });
  };

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({ country: event.target.value });
  };

  imageUpload = async (e) => {
    console.log("e", e.target.files);
    let file = e.target.files;
    //   if(file.lenght>0)
    //   {
    let dataSend = { buckate: true, buckate_name: "appIcon" };

    let result = await HttpClient.newFileUpload(
      "image-upload/category",
      e.target.files[0],
      dataSend
    );
    // console.log("result", result);
    if (result && result.status) {
      this.setState({ img_url: result.url, image_select: true });
    }

    //   }
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleAcceptedFiles = (files) => {
    // console.log("object", event.target.files[0]);
    // setprofile(event.target.files[0]);
    let file = {
      preview: URL.createObjectURL(files[0]),
      formattedSize: this.formatBytes(files[0].size),
    };
    console.log("image", files[0]);
    let arr = [];
    arr.push(file);
    this.setState({
      // images: files[0],
      uploaded_images: arr,
    });
    console.log("uploaded_images", this.state.uploaded_images);
  };
  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Name",
          field: "name",

          width: 270,
        },
        {
          label: "Username",
          field: "email",

          width: 270,
        },
        // {
        //   label: "Image",
        //   field: "img",

        //   width: 270,
        // },
        {
          label: "Action",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.alert ? (
                      <UncontrolledAlert
                        color={this.state.type}
                        className="alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.type == "warning" ? (
                          <i className="mdi mdi-alert-outline mr-2"></i>
                        ) : this.state.type == "success" ? (
                          <i className="mdi mdi-check-all mr-2"></i>
                        ) : this.state.type == "danger" ? (
                          <i className="mdi mdi-block-helper mr-2"></i>
                        ) : null}
                        {this.state.message}
                      </UncontrolledAlert>
                    ) : null}

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.fname}
                          placeholder="Enter Name"
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ fname: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Username
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          value={this.state.email}
                          placeholder="Enter Username"
                          readOnly={this.state.edit_enable}
                          id="example-text-input"
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    {!this.state.edit_enable && (
                      <FormGroup row>
                        <Label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Password
                        </Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="password"
                            value={this.state.password}
                            placeholder="Enter Password"
                            id="example-text-input"
                            onChange={(val) => {
                              this.setState({ password: val.target.value });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    )}

                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submit();
                          }}
                        >
                          {this.state.edit_enable ? "Update" : "Submit"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>

                    <br></br>

                    <hr></hr>

                    {this.state.edit_enable && (
                      <>
                        <h3>Change Your Password</h3>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            New Password
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="password"
                              value={this.state.newpassword}
                              placeholder="Enter Password"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({
                                  newpassword: val.target.value,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            Confirm Password
                          </Label>
                          <Col md={10}>
                            <Input
                              className="form-control"
                              type="password"
                              value={this.state.cpassword}
                              placeholder="Enter Password"
                              id="example-text-input"
                              onChange={(val) => {
                                this.setState({ cpassword: val.target.value });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-0">
                          <div className="button-items pt-4">
                            <Button
                              color="primary"
                              type="button"
                              className="waves-effect waves-light mr-1"
                              onClick={() => {
                                this.changepassword();
                              }}
                            >
                              Change Password
                              <i className="ri-arrow-right-line align-middle ml-1"></i>
                            </Button>
                          </div>
                        </FormGroup>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
