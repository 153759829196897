import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import MembersDetails from "./MemberDetails";
import AddMember from "./AddMember";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import db from "../../Firebase/fbconfig";
import { getDatabase, ref, child, get } from "firebase/database";
class FormElements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Groups", link: "#" },
        { title: "Manage Groups", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      // page states
      name: "",
      desc: "",
      duration: "",
      price: "",
      data: [],
      alert: false,
      message: "",
      type: "",
      result: [],
      edit_enable: false,
      edit_item_id: "",
      image_select: false,
      img_url: "",
      category_type: "",
      size: [{ name: "" }],
      grouplist: [],
      ismodalopen: false,
    };
  }

  componentDidMount = async () => {
    this.fetchData();
  };

  onCloseCallBack = (val) => {
    console.log("object", val);
    this.setState({
      ismodalopen: val,
    });
  };

  fetchData = async () => {
    const dbRef = ref(db);
    get(child(dbRef, `Chat`))
      .then((snapshot) => {
        // console.log(snapshot.val());
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((element) => {
            let dom = {
              id: element.val().key,
              name:
                typeof element.val().members != "undefined"
                  ? element.val().Name
                  : "",
            };
            // console.log("dom", dom);
            arr.push(dom);
          });
          // console.log("arr", arr);
          this.setState({
            grouplist: arr,
          });
          let data = [];
          let i = 1;
          //   console.log("grouplist",arr.filter(item=>item.name!=""));
          this.setState({ result: arr.filter((item) => item.name != "") });
          this.state.result.forEach((element, index) => {
            let rows = {
              sl: i,
              name: element.name,
              action: (
                <>
                  <button
                    title="View Members"
                    className="btn btn-success mr-2"
                    onClick={() => {
                      this.setState({
                        activeid: element.id,
                      });
                      setTimeout(() => {
                        this.setState({
                          ismodalopen: true,
                        });
                      }, 200);
                    }}
                  >
                    View Details
                  </button>
                </>
              ),
            };
            i++;
            data.push(rows);
          });
          this.setState({
            data: data,
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const data = {
      columns: [
        {
          label: "Sl.",
          field: "sl",

          width: 150,
        },
        {
          label: "Group Name",
          field: "name",

          width: 270,
        },
        {
          label: "View Members",
          field: "action",

          width: 100,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xl={12}>
                <div className="card p-3">
                  {/* <MDBDataTable responsive bordered data={data} /> */}
                  {this.state.ismodalopen ? (
                    <MembersDetails
                      status={true}
                      onCloseCallBack={this.onCloseCallBack}
                      data={this.state.activeid}
                    />
                  ) : null}

                  <MDBDataTableV5
                    hover
                    entriesOptions={[5, 20, 25]}
                    entries={5}
                    pagesAmount={4}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FormElements;
