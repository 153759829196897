import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
} from "reactstrap";
import moment from "moment";
import db from "../../../Firebase/fbconfig";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
} from "firebase/database";

const DeductbalanceModal = (props) => {
  const [bal, setbal] = useState();

  const adding = () => {
    let userData = {};
    if (bal != "") {
      const dbRef = ref(db);
      get(child(dbRef, `/user/${props.data}`)).then((snapshot) => {
        if (snapshot.exists()) {
          userData = {
            balance: snapshot.val().balance - Number(bal),
            displayName: snapshot.val().displayName,
            fcmToken: snapshot.val().fcmToken,
            id: snapshot.val().id,
            image: snapshot.val().image,
            is_deleted: snapshot.val().is_deleted,
            lastActiveTime: snapshot.val().lastActiveTime,
            name: snapshot.val().name,
            onCall: snapshot.val().onCall,
            online: snapshot.val().online,
            pass: snapshot.val().pass,
            type: snapshot.val().type,
            userName: snapshot.val().userName,
          };
          const updates = {};
          updates["/user/" + props.data] = userData;
          update(ref(db), updates);
          setbal();
          props.close();
        }
      });
    }
  };

  const handleChange = (event) => {
    setbal(event.target.value);
  };

  return (
    <div>
      <div className="modal-dialog ">
        <div
          className="modal-content modal_body"
          style={{ height: "200px", overflowY: "auto" }}
        >
          <button
            type="button"
            className="cross close ml-auto px-4 py-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.close()}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body ">
            <h4 className="text-center">Deduct Balance</h4>

            <Input
              type="text"
              placeholder="0"
              value={bal}
              onChange={handleChange}
            />

            <button
              title="Deduct Balance"
              className="btn btn-danger mr-2"
              onClick={adding}
            >
              <Label>Deduct Balance</Label>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeductbalanceModal;
