import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledAlert,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import HttpClient from "./../../utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import db from "../../Firebase/fbconfig";
import {
  ref,
  child,
  get,
  set,
  update,
  onChildAdded,
  query,
} from "firebase/database";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// import images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Profile", link: "#" },
        { title: "Edit Profile", link: "#" },
      ],
      activeTab: "1",
      plans: [],
      activePlan: [],
      userId: "",
      userDetails: {},
      alert: false,
      message: "",
      type: "",

      name_old: "",
      email_old: "",
      mobile_old: "",

      image: "",
      name: "",
      email: "",
      mobile: "",
      address: "",
      edit_enable: true,
      _id: "",
      new_pass: "",
      con_new_pass: "",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  submitPass = async () => {
    if(this.state.new_pass == '' && this.state.con_new_pass == ''){
      this.setState({
        alert: true,
        message: "Please Enter Password And Confirm Password",
        type: "warning",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);

      return;
    }
    if(this.state.new_pass == this.state.con_new_pass){
      update(ref(db, `/admin`), {
        email: 'admin@gmail.com',
        password: this.state.new_pass
      })
      .then(() => {
        this.setState({
          alert: true,
          message: "Password Updated.",
          type: "success",
          new_pass: '',
          con_new_pass: ''
        });

        setTimeout(() => {
          this.setState({
            alert: false,
            message: "",
            type: "",
          });
        }, 3000);

      })
    }else{
      this.setState({
        alert: true,
        message: "Password Not Matched.",
        type: "warning",
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          message: "",
          type: "",
        });
      }, 3000);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.breadcrumbItems[0].title}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg={12}>
                {this.state.alert ? (
                  <UncontrolledAlert
                    color={this.state.type}
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    {this.state.type == "warning" ? (
                      <i className="mdi mdi-alert-outline mr-2"></i>
                    ) : this.state.type == "success" ? (
                      <i className="mdi mdi-check-all mr-2"></i>
                    ) : this.state.type == "danger" ? (
                      <i className="mdi mdi-block-helper mr-2"></i>
                    ) : null}
                    {this.state.message}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="mb-6">
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={avatar2}
                    alt="Skote"
                    style={{
                      alignSelf: "center",
                      width: 200,
                      height: 200,
                      borderRadius: 100,
                      marginTop: 20,
                    }}
                  />
                  <CardBody>
                    <CardTitle className="mt-0">Profile Details</CardTitle>
                    <div>
                      <small class="text-muted">Name: </small>
                      <p>Admin</p>
                      <hr /> <small class="text-muted">Email address: </small>
                      <p>admin@gmail.com</p>
                      <hr />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={8} className="mb-6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Profile</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={'Admin'}
                          onChange={(val) => {
                            this.setState({ name: val.target.value });
                          }}
                          placeholder={"Enter name"}
                          disabled = {true}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Email
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          defaultValue="Artisanal kale"
                          id="example-text-input"
                          value={'admin@gmail.com'}
                          onChange={(val) => {
                            this.setState({ email: val.target.value });
                          }}
                          placeholder={"Enter email"}
                          disabled = {true}
                        />
                      </Col>
                    </FormGroup>{" "}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Edit Password</h4>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Enter New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="password"
                          id="example-text-input"
                          value={this.state.new_pass}
                          onChange={(val) => {
                            this.setState({ new_pass: val.target.value });
                          }}
                          placeholder={"Enter New Password"}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Confirm New Password
                      </Label>
                      <Col md={10}>
                        <Input
                          className="form-control"
                          type="text"
                          id="example-text-input"
                          value={this.state.con_new_pass}
                          onChange={(val) => {
                            this.setState({ con_new_pass: val.target.value });
                          }}
                          placeholder={"Confirm New Password"}
                        />
                      </Col>
                    </FormGroup>{" "}
                    <FormGroup className="mb-0">
                      <div className="button-items pt-4">
                        <Button
                          color="primary"
                          type="button"
                          className="waves-effect waves-light mr-1"
                          onClick={() => {
                            this.submitPass();
                          }}
                        >
                          {this.state.edit_enable
                            ? "Update Password"
                            : "Update Password"}{" "}
                          <i className="ri-arrow-right-line align-middle ml-1"></i>
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pricing;
